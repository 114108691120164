import React from 'react';
import axios from 'axios';

class Navbar extends React.Component {

    constructor(props) {
        super(props);
        const { csrfCookieName, csrfHeaderName } = this.props;
        axios.defaults.xsrfCookieName = csrfCookieName;
        axios.defaults.xsrfHeaderName = csrfHeaderName;
        axios.defaults.withCredentials = true;

        this.state = {
            // isCollapsed: false,
            // menuIsOpen: false,
            userIsAuthenticated: false
        };

        this.getUserIsAuthenticated = this.getUserIsAuthenticated.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    componentDidMount() {
        this.getUserIsAuthenticated();
    }

    getUserIsAuthenticated() {
        const self = this;
        const { apiBaseUrl } = this.props;

        axios.get(apiBaseUrl + '/users/authenticated/')
            .then(function (response) {
                self.setState(prevState => ({
                    userIsAuthenticated: response.data.data.userIsAuthenticated
                }));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    openMenu(e) {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "hidden";
        document.getElementById('overlay').style.display = 'block';
    }

    closeMenu(e) {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "visible";
        document.getElementById('overlay').style.display = 'none';
    }

    render() {

        const {
            logoUrl,
            homeUrl,
            loginUrl,
            accountUrl,
            costingListUrl,
            downloadListUrl,
            costingNewUrl,
            logoutUrl
        } = this.props;

        const { userIsAuthenticated } = this.state;

        return (
            <nav className="navbar bg-light navbar-expand-sm fixed-top">
                <div className="container">
                    <a className="navbar-brand" href={homeUrl}>
                        <img src={logoUrl} height="60" />
                    </a>
                    {userIsAuthenticated &&
                        <button className="navbar-toggler" type="button" onClick={(e) => { this.openMenu(e) }}>
                            <i className="las la-lg la-bars"></i>
                        </button>
                    }
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav ml-auto">
                            {userIsAuthenticated &&
                                <li className="nav-item">
                                    <a className="nav-link" href={accountUrl}>MI CUENTA</a>
                                </li>
                            }
                            {userIsAuthenticated &&
                                <li className="nav-item mr-2">
                                    <a className="nav-link" href={costingListUrl}>MIS COSTEOS</a>
                                </li>
                            }
                            {userIsAuthenticated &&
                                <li className="nav-item mr-2">
                                    <a className="nav-link" href={downloadListUrl}>PAC</a>
                                </li>
                            }
                            {userIsAuthenticated &&
                                <li className="nav-item mr-2">
                                    <a className="nav-link" href={logoutUrl}>SALIR</a>
                                </li>
                            }
                        </ul>
                        {userIsAuthenticated &&
                            <form className="form-inline my-2 my-lg-0">
                                <a className="btn btn-primary my-2 my-sm-0" href={costingNewUrl}>NUEVO COSTEO</a>
                            </form>
                        }
                    </div>
                </div>
                <div id='overlay' className='overlay bg-primary'>
                    <a href="#" className="overlay-close-button text-white"
                        onClick={(e) => { this.closeMenu(e) }}>&times;</a>
                    <div className="container h-100">
                        <div className='row h-100 vw-100 align-items-center'>
                            <div className='col-12 my-auto'>
                                <ul className="nav flex-column nav-pills nav-fill h-100">
                                    {userIsAuthenticated &&
                                        <li className="nav-item">
                                            <a className="nav-link nav-link-menu" href={costingNewUrl}>NUEVO COSTEO</a>
                                        </li>
                                    }
                                    {userIsAuthenticated &&
                                        <li className="nav-item">
                                            <a className="nav-link nav-link-menu" href={costingListUrl}>MIS COSTEOS</a>
                                        </li>
                                    }
                                    {userIsAuthenticated &&
                                        <li className="nav-item">
                                            <a className="nav-link nav-link-menu" href={downloadListUrl}>PAC</a>
                                        </li>
                                    }
                                    {userIsAuthenticated &&
                                        <li className="nav-item">
                                            <a className="nav-link nav-link-menu" href={accountUrl}>MI CUENTA</a>
                                        </li>
                                    }
                                    {userIsAuthenticated &&
                                        <li className="nav-item">
                                            <a className="nav-link nav-link-menu" href={logoutUrl}>SALIR</a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;